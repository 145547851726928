<template>
  <b-card>
    <div class="text-center">
      <b>Please Upgrade to Access this feature</b>
    </div>
    <div class="d-flex justify-content-center">
      <lenon-button label="Upgrade Now" class="mt-2" @onClick="$router.push({name:'subscription'})"/>
    </div>
  </b-card>
</template>

<script>
import {
BCard
} from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton'
export default {
  name: 'LenonFreemium',
  components:{
    LenonButton,
    BCard
  }
}
</script>

<style scoped>

</style>
