<template>
  <div class="academic-years">
    <lenon-freemium v-if="isFreemium"/>
    <lenon-list-page
        v-else
      title="Students Performance"
      :columns="columns"
      :rows="performances"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      :show-details="true"
      :show-profile-photo="true"
      search-placeholder="Search"
    >
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-center"
          style="margin-bottom: -20px;"
        >
          <div
            class="mr-1"
            style="margin-bottom: 15px"
          >
            <b-form-checkbox
              v-model="overall"
              v-b-tooltip.hover="'Overall performance instead of individual subject performances'"
            >
              Overall
            </b-form-checkbox>
          </div>
          <lenon-select
            v-model="selectedAcademicYearId"
            placeholder="Academic Year"
            :options="academicYears"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedClassId"
            placeholder="Select Class"
            :options="classes"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-if="!overall"
            v-model="selectedSubjectId"
            style="width: 200px !important;"
            placeholder="Select Subject"
            :options="subjects"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-center"
        >
          <lenon-button
            v-b-toggle.performance
            icon-only
            icon="MoreVerticalIcon"
            variant="flat-primary"
          />
        </div>
      </template>
      <template slot="table-header">
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="col-12"
        >
          <b-collapse
            id="performance"
          >
            <div class="row">
              <div class="col-md-6 mb-1">
                <b-form-checkbox
                  v-model="overall"
                >
                  Overall Performance
                </b-form-checkbox>
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedAcademicYearId"
                  placeholder="Academic Year"
                  :options="academicYears"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedClassId"
                  placeholder="Select Class"
                  :options="classes"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-if="!overall"
                  v-model="selectedSubjectId"
                  style="width: 200px !important;"
                  placeholder="Select Subject"
                  :options="subjects"
                  label-name="name"
                  value-name="id"
                  class="mr-1"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
      <template #full_name="{row}">
        {{ row.item.last_name }} {{ row.item.first_name }} {{ row.item.middle_name || '' }}
      </template>
      <template #status="{row}">
        <b-badge :variant="getImprovingVariant(row.item.improving)">
          <feather-icon :icon="getImprovingIcon(row.item.improving)" />
          <span>{{ getImprovingText(row.item.improving) }}</span>
        </b-badge>
      </template>
      <template #performance="{row}">
        <b-badge :variant="getImprovingVariant(row.item.status)">
          <span>{{ getStatusText(row.item.status) }}</span>
        </b-badge>
      </template>
    </lenon-list-page>

  </div>
</template>

<script>

import {
  BFormCheckbox, VBTooltip, BBadge, BCollapse, VBToggle,BCard
} from 'bootstrap-vue'
import { FETCH_STUDENTS_PERFORMANCE_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonButton from '@/lenon/components/LenonButton.vue'
import LenonFreemium from '@/lenon/components/LenonFreemium'

export default {
  name: 'FeePayments',
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  components: {
    LenonFreemium,
    LenonButton,
    LenonSelect,
    LenonListPage,
    BFormCheckbox,
    BBadge,
    BCollapse,
    BCard
  },
  mixins: [showToast],
  data() {
    return {
      columns: [
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'full_name',
          label: 'Student Full Name',
        },
        {
          key: 'previous_average',
          label: 'Last Average',
          sortable: true,
        },
        {
          key: 'current_average',
          label: 'Current Average',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'performance',
          label: 'Performance',
        },
      ],
      overall: false,
      tableLoading: false,
      selectedAcademicYearId: null,
      selectedSubjectId: null,
      selectedClassId: null,
    }
  },
  computed: {
    isFreemium() {
      return this.$store.getters['auth/subscription']?.freemium
    },
    monitorSettings() {
      return this.overall ? (+this.selectedClassId * this.selectedAcademicYearId) : (+this.selectedClassId * +this.selectedSubjectId * this.selectedAcademicYearId)
    },
    previousSetting() {
      return this.$store.getters['performance/previousSettings']
    },
    performances() {
      return this.$store.getters['performance/performances']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    subjects() {
      return this.$store.getters['studentGrading/subjects']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    valid() {
      return this.overall ? this.selectedClassId && this.selectedAcademicYearId : this.selectedAcademicYearId && this.selectedClassId && this.selectedSubjectId
    },
  },
  watch: {
    overall(overall) {
      this.fetchStudentsPerformance()
    },
    monitorSettings() {
      if (this.valid) {
        this.fetchStudentsPerformance()
        this.$store.commit('performance/setSettings', {
          selectedAcademicYearId: this.selectedAcademicYearId,
          selectedClassId: this.selectedClassId,
          selectedSubjectId: this.selectedSubjectId,
          overall: this.overall,
        })
      } else {
        this.$store.commit('performance/setPerformances', [])
      }
    },
  },
  mounted() {
    this.setSettings()
    this.$store.commit('performance/setPerformances', [])
  },
  methods: {
    getImprovingVariant(improving) {
      if (improving === 1) {
        return 'success'
      }
      if (improving === 2) {
        return 'warning'
      }
      return 'danger'
    },
    getImprovingIcon(improving) {
      if (improving === 1) {
        return 'ArrowUpIcon'
      }
      if (improving === 2) {
        return 'CheckIcon'
      }
      return 'ArrowDownIcon'
    },
    getImprovingText(improving) {
      if (improving === 1) {
        return 'Improving'
      }
      if (improving === 2) {
        return 'Consistent'
      }
      return 'Degrading'
    },
    getStatusText(status) {
      if (status === 1) {
        return 'Excellent'
      }
      if (status === 2) {
        return 'Good'
      }
      return 'Poor'
    },
    // eslint-disable-next-line consistent-return
    rowClass(item, type) {
      if (type === 'row') {
        if (item.status === 1) {
          return 'table-success'
        }
        if (item.status === 2) {
          return 'table-warning'
        }
        return 'table-danger'
      }
    },
    setSettings() {
      this.selectedAcademicYearId = this.previousSetting?.selectedAcademicYearId
      this.selectedSubjectId = this.previousSetting?.selectedSubjectId
      this.selectedClassId = this.previousSetting?.selectedClassId
      this.overall = this.previousSetting?.overall || false
    },
    fetchStudentsPerformance() {
      if (this.valid) {
        this.tableLoading = true
        this.$apollo.query({
          query: FETCH_STUDENTS_PERFORMANCE_Q,
          variables: {
            input: {
              academic_year_id: this.selectedAcademicYearId,
              class_id: this.selectedClassId,
              subject_id: this.selectedSubjectId,
              overall: this.overall,
            },
          },
        })
          .then(res => {
            this.tableLoading = false
            if (res.data) {
              this.$store.commit('performance/setPerformances', res.data?.studentsPerformance)
            } else {
              this.showError('Failed to load data')
            }
          })
          .catch(err => {
            this.tableLoading = false
            this.showError('Something went wrong, please try again')
          })
      }
    },
  },
}
</script>
